import {DateTime} from 'luxon';
import {Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {Router} from "@angular/router";
import jsPDF from 'jspdf';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { Observable, catchError, map, of, from } from 'rxjs';
import { concatMap, toArray } from 'rxjs/operators';
import * as XLSX from 'xlsx';  
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-anamneses', templateUrl: './anamneses.component.html', styleUrls: ['./anamneses.component.scss']
})
export class AnamnesesComponent {

    public dataPesquisa: any = {
        Paciente: "",
        Grupo: "",
        Avaliador: "",
        Cupom: "",
        Evento: "",
        PeriodoDe: "",
        PeriodoAte: "",
        Z: "",
        Status: "",
        Preenchida: null,
        Avaliada: null,
    };
    public loadingExcel: boolean = false; 
    public listaAnamneses: any;
    public loadingPDF: boolean = false;
    public listaDadosPacientes: any;
    public nenhumaAnamneseEncontrada: boolean = true;
    public anamnesesSelecionadas: number[] = [];
    public loadingListaAnamneses: boolean = false;
    public avaliarLoading: boolean = false;
    public loadingDevolverAnamnese: boolean = false;
    public pacienteIdSelecionado: number;
    public opcoesPreenchida = [{value: '', descricao: 'Selecione...'}, {
        value: 'true', descricao: 'Sim'
    }, {value: 'false', descricao: 'Não'}];
    public opcoesStatus = [{value: '', descricao: 'Selecione...'}, {value: '1', descricao: 'Em Branco'}, {
        value: '2', descricao: 'Incompleto'
    }, {value: '3', descricao: 'Completo'}];

    constructor(private anamneseService: AnamneseService, private toastr: ToastrService, 
        private router: Router, private professorService: ProfessorService) {
    }

    ngOnInit(): void {
        // Código de inicialização, se necessário
    }


    getListaAnamneses(): void {
        this.loadingListaAnamneses = true;
        this.anamnesesSelecionadas = [];
        this.anamneseService.postAnamneseConsultar(this.dataPesquisa).subscribe({
            next: (response) => {
                this.listaAnamneses = response;
                this.nenhumaAnamneseEncontrada = this.listaAnamneses.length === 0;
                this.loadingListaAnamneses = false;
                setTimeout(() => {
                    this.scrollToElementById('listaPacientes');
                    }, 1000);
            }, error: (error) => {
                if (error?.error?.Message) {
                    this.toastr.error(error.error.Message);
                } else {
                    this.toastr.error('Erro ao buscar anamneses, contate o suporte')
                }

                this.loadingListaAnamneses = false;
            }
        });
    }

    buscaDadosDeCadaPaciente(dados: any): Observable<any> {
        const body = {
            Perfil: "",
            Situacao: "",
            Tipo: "Nome",
            Valor: dados.Paciente
        };
        return this.professorService.postListaUsuariosAvaliador(body).pipe(
            map(response => {
                // Supondo que o email esteja no objeto response
                const email = response[0]?.Email ?? 'Email não encontrado';
                return { ...dados, email }; // Retorna os dados originais com o email adicionado
            }),
            catchError(error => {
                this.toastr.error('Erro ao buscar dados do paciente');
                return of({ ...dados, email: 'Erro ao buscar email' }); // Retorna mesmo em caso de erro
            })
        );
    }
    


    baixarPDF() {
        this.loadingPDF = true;

        const doc = new jsPDF();

        // Filtrar as anamneses que ainda não foram preenchidas
        const listaAnamnesesSemPreenchimento = this.listaAnamneses.filter((anamnese) => anamnese?.Preenchida == false);

        // Iniciar a execução sequencial das requisições
        from(listaAnamnesesSemPreenchimento).pipe(
            concatMap((anamnese) => this.buscaDadosDeCadaPaciente(anamnese)),
            toArray() // Converte todas as respostas obtidas em um array
        ).subscribe({
            next: (pacientesComEmail) => {
                // Carregar a logo e só depois gerar o PDF
                const logo = new Image();
                logo.src = 'assets/img/LogoIconTextPlataformaSafe.png';

                logo.onload = () => {
                    doc.addImage(logo, 'PNG', 150, 10, 50, 40); // Adiciona a imagem

                    const totalAnamneses = listaAnamnesesSemPreenchimento.length;

                    // Adicionando cabeçalho
                    doc.setFontSize(14);
                    doc.text('Relatório de Pacientes sem Anamnese', 14, 20);
                    doc.setFontSize(12);
                    doc.text(`Total: ${totalAnamneses}`, 14, 30);

                    // Adicionando tabela
                    (doc as any).autoTable({
                        head: [['Protocolo', 'Paciente', 'Avaliador', 'Profissão', 'Cadastro', 'Email']],
                        body: pacientesComEmail.map((relatorio) => [
                            relatorio.Protocolo,
                            relatorio.Paciente,
                            relatorio.Professor,
                            relatorio.Ocupacao ?? 'Não informado',
                            this.formatDate(relatorio.Cadastro),
                            relatorio.email // Inclui o email no relatório
                        ]),
                        startY: 50,
                        columnStyles: {
                            0: { cellWidth: 25 }, // Defina a largura da coluna 'Protocolo'
                            1: { cellWidth: 35 }, // Defina a largura da coluna 'Paciente'
                            2: { cellWidth: 30 }, // Defina a largura da coluna 'Avaliador'
                            3: { cellWidth: 25 }, // Defina a largura da coluna 'Profissão'
                            4: { cellWidth: 25 }, // Defina a largura da coluna 'Cadastro'
                            5: { cellWidth: 40 }  // Defina a largura da coluna 'Email'
                        },
                        styles: {
                            fontSize: 10,
                            cellPadding: 2,
                            overflow: 'linebreak',
                            columnWidth: 'wrap'
                        },
                    });

                    // Baixar o PDF
                    doc.save(`relatorio_biomarcadores_SAFE.pdf`);
                    this.loadingPDF = false; // Para o loading após o download
                };

                logo.onerror = (error) => {
                    this.toastr.error('Erro ao carregar o logo, por favor, tente novamente.');
                    this.loadingPDF = false; // Para o loading em caso de erro no logo
                };
            },
            error: () => {
                this.toastr.error('Erro ao gerar o PDF, tente novamente.');
                this.loadingPDF = false; // Para o loading em caso de erro nas requisições
            }
        });
    }

    baixarExcel() {
        this.loadingExcel = true;

         
        const listaAnamnesesSemPreenchimento = this.listaAnamneses.filter((anamnese) => anamnese?.Preenchida == false);

        from(listaAnamnesesSemPreenchimento).pipe(
            concatMap((anamnese) => this.buscaDadosDeCadaPaciente(anamnese)),
            toArray()  
        ).subscribe({
            next: (pacientesComEmail) => {
                 
                const dataForExcel = pacientesComEmail.map((relatorio) => ({
                    Protocolo: relatorio.Protocolo,
                    Paciente: relatorio.Paciente,
                    Avaliador: relatorio.Professor,
                    Profissão: relatorio.Ocupacao ?? 'Não informado',
                    Cadastro: this.formatDate(relatorio.Cadastro),
                    Email: relatorio.email
                }));

                
                const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Relatório Pacientes");

                 
                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

             
                const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
                saveAs(blob, `relatorio_biomarcadores_SAFE.xlsx`);
                this.loadingExcel = false; // Para o loading após o download
            },
            error: () => {
                this.toastr.error('Erro ao gerar o Excel, tente novamente.');
                this.loadingExcel = false; // Para o loading em caso de erro
            }
        });
    }

    

    selectAll(event: any): void {
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox: any) => {
            checkbox.checked = event.target.checked;
        });
        this.anamnesesSelecionadas = event.target.checked ? this.listaAnamneses.map(item => item.ID) : [];
    }

    selecionarPaciente(pacienteId: number): void {
        const anamnese = this.listaAnamneses?.find(a => a.IdentificadorHash == pacienteId)
        const index = this.anamnesesSelecionadas.indexOf(pacienteId);

        if (index === -1) {
            this.pacienteIdSelecionado = anamnese?.ID
            this.anamnesesSelecionadas.push(pacienteId);
        } else {
            this.pacienteIdSelecionado = undefined
            this.anamnesesSelecionadas.splice(index, 1);
        }
    }

    scrollToElementById(id: string) {
     
        const element = document.getElementById(id)
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    
      }

    handleDevolverAnamnese(): void {
        if (this.anamnesesSelecionadas.length === 0) {
            this.toastr.warning('Selecione ao menos um paciente para devolver a anamnese');
            return;
        }

        let body = {
            //@ts-ignore
            Hash: this.anamnesesSelecionadas[0]?.IdentificadorHash
        }
        this.loadingDevolverAnamnese = true;

        this.anamneseService.putDevolverAnamnese(body).subscribe({
            next: (response) => {
                this.toastr.success('Anamnese devolvida com sucesso');
                this.getListaAnamneses();
                this.loadingDevolverAnamnese = false;
            }, error: (error) => {
                if (error?.error?.Message) {
                    this.toastr.error(error.error.Message);
                } else {
                    this.toastr.error('Erro ao devolver anamnese, contate o suporte')
                }
                this.loadingDevolverAnamnese = false;
            }
        });
    }


    onAvaliar() {
        this.avaliarLoading = true;
        if (this.anamnesesSelecionadas.length === 0) {
            this.toastr.warning('Selecione ao menos um paciente para avaliar a anamnese');
            return;
        }
        // @ts-ignore
        const Hash: any = this.anamnesesSelecionadas[0]?.IdentificadorHash;
        this.anamneseService.getObterLinkAvaliacao(Hash).subscribe({
            next: (response: any) => {
                this.avaliarLoading = false;
                const urlParts = response.split('/'); 
                const hash = urlParts[urlParts.length - 1]; 

                this.router.navigate(['/dashboard/acesso-avaliador/usuarios-avaliacoes/alterar/', hash]);
   
            }, error: (error) => {
                this.avaliarLoading = false;
                if (error?.error?.Message) {
                    this.toastr.error(error.error.Message);
                } else {
                    this.toastr.error('Erro ao obter link de avaliação, contate o suporte')
                }
            }
        });
        
    }

    handleVisualizarCadastro(ID: number): void {
        if (!ID) {
            this.toastr.warning('Selecione um paciente para visualizar o cadastro');
            return;
        }
        //@ts-ignore
        //abre uma nova aba com o link do cadastro
        window.open(`https://plataformasafe.com.br/dashboard/acesso-avaliador/usuarios/alterar/${ID}`, "_blank")
    }

    handleLaudo(context: string): void {

        if (!this.anamnesesSelecionadas.length) {
            this.toastr.warning('Selecione ao menos um paciente para baixar o laudo');
            return;
        }
        if (context === 'incompleto') {
            //@ts-ignore
            window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoSemDadosPessoais/${this.anamnesesSelecionadas[0]?.IdentificadorHash}`, "_blank")
            return
        }
         //@ts-ignore
        window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoCompleto/${this.anamnesesSelecionadas[0]?.IdentificadorHash}`, "_blank")
    }

    handleVisualizarPacienteAnamnese(): void {
         //@ts-ignore
        this.router.navigate([`/dashboard/acesso-avaliador/anamneses/visualizar/${this.anamnesesSelecionadas[0]?.IdentificadorHash}`]);

    }

    handleVisualizarAnamnese(pacienteId: number): void {
        window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoCompleto/${pacienteId}`, "_blank")

   }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
    }

}
