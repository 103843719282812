import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {getHeaders} from "@/utils/getHeaders";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {ToastrService} from "ngx-toastr";
import {Cupom, CupomService} from "@services/dashboard/cupom/cupom.service";
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { DadosCadastraisService } from '@services/dashboard/dados-cadastrais/dados-cadastrais.service';
import { AppService } from '@services/app.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

interface RelatorioBiomarcadorSafe {
    Aprovados: number
    Biomarcador: string
    PercentualAprovado: number
    PercentualReprovado: number
    Reprovados: number
    TotalBiomarcadores: number
    TotalCheckups: number
}

@Component({
    selector: 'app-relatorio-biomarcadores-safe',
    templateUrl: './relatorio-biomarcadores-safe.component.html',
    styleUrls: ['./relatorio-biomarcadores-safe.component.scss']
})
export class RelatorioBiomarcadoresSafeComponent implements OnInit, OnDestroy {
    public formRelatorioBiomarcadores: FormGroup;
    public isLoadingRelatorios: boolean = false
    public relatorios: Array<RelatorioBiomarcadorSafe> = []
    public cupons: Array<Cupom> = []
    public listaAvaliadores: Array<any> = []
    private api = environment.endpoint_api
    public usuarioLogado: any
    public isAdm: boolean = false

    constructor(private http: HttpClient, 
        private professorService: ProfessorService,
        private toastr: ToastrService, 
        private cupomService: CupomService,
        private dadosCadastraisService: DadosCadastraisService,
        private appService: AppService
    ) {
    }

    ngOnInit() {
        const classeContexto = this

        this.formRelatorioBiomarcadores = new FormGroup({
            // email: new FormControl(null, [Validators.required]),
            // senha: new FormControl(null, [])
            AvaliadorID: new FormControl(0, []),
            GrupoID: new FormControl(0, []),
            CupomID: new FormControl(0, []),
            PeriodoDe: new FormControl('', []),
            PeriodoAte: new FormControl('', []),
            Base: new FormControl(2, []),
            Escolaridade: new FormControl('0', []),
            FixaEtaria: new FormControl('0', []),
            Tipo: new FormControl('2', []),
            Sexo: new FormControl('0', []),
            UF: new FormControl('T', []),
        });

 
        this.usuarioLogado = this.appService.getDadosUsuarioLogadoStorage()
        this.isAdm = this.usuarioLogado?.Perfil?.ID == 1 ? true : false
        
       


        this.cupomService.getCuponsAtivos().subscribe({
            next(response) {

                classeContexto.cupons = (response as Array<Cupom>).sort((a, b) => {
                    return a.Descricao.localeCompare(b.Descricao);
                });
            },
            error() {
                console.log('Erro ao buscar cupons')
            }
        });
        
        this.atualizarListaAvaliadores()

    }
   
    
    baixarPDF() {
        const doc = new jsPDF();
    
        // Adicionando a logo no canto superior direito
        const logo = new Image();
        logo.src = 'assets/img/LogoIconTextPlataformaSafe.png'; // Caminho para a imagem
        logo.onload = () => {
            doc.addImage(logo, 'PNG', 150, 10, 50, 40); // Adiciona a imagem (x: 150, y: 10, largura: 50, altura: 20)
     
            const totalCheckups = this.relatorios[0]?.TotalBiomarcadores
            // Adicionando cabeçalho
            doc.setFontSize(14);
            doc.text('Relatório de CHECK-UPS SAFE® Completos ', 14, 20);
            doc.setFontSize(12);
            doc.text(`Filtrar Usuários - ${this.getLabelFiltrarPor()}`, 14, 30);
            {
                this.getLabelFiltrarPor() == 'POR AVALIADOR' && doc.text(`Avaliador: ${this.listaAvaliadores.find(avaliador => avaliador.ID == this.formRelatorioBiomarcadores.get('AvaliadorID').value)?.Nome}`, 14, 40);
            }
             doc.text(`Total: ${totalCheckups}`, 14, 50);
    
            // Adicionando tabela
            (doc as any).autoTable({
                head: [['#', 'Biomarcador', 'Percentual Aprovado', 'Percentual Reprovado', 'Total Aprovados', 'Total Reprovados']],
                body: this.relatorios.map((relatorio, index) => [
                    index + 1,
                    relatorio.Biomarcador,
                    relatorio.PercentualAprovado + '%',
                    relatorio.PercentualReprovado + '%',
                    relatorio.Aprovados,
                    relatorio.Reprovados,                     
                ]),
                startY: 60,
            });
    
            // Baixar o PDF
            doc.save(`relatorio_biomarcadores_SAFE}.pdf`);
        };
    }
    
    
    

    atualizarListaAvaliadores() {
        const classeContexto = this
        this.professorService.getObterListaAvaliadores().subscribe({
            next(response) {
                classeContexto.listaAvaliadores = response as Array<any>
            }, error() {
            }
        })
    }


    getLabelFiltrarPor() {
         switch (this.formRelatorioBiomarcadores.get('Base').value) {
            case 1:
                return 'TODA BASE';

            case 2:
                return 'POR AVALIADOR';

            case 3:
                return 'POR GRUPO';

            case 4:
                return 'POR CUPOM';

            default:
                return ''
        }
    }

    onChangeCheckboxValue(name: string, value: any) {
        this.formRelatorioBiomarcadores.get(name).setValue(value)
    }

    onClickPesquisar() {
        const headers = getHeaders()
        const classeContext = this
        const body = this.formRelatorioBiomarcadores.value
        this.isLoadingRelatorios = true

        this.http.post(this.api + "/Relatorio/BioMarcadoresSafe", body, {headers}).subscribe({
            next(response) {
                classeContext.relatorios = response as Array<RelatorioBiomarcadorSafe>
                classeContext.isLoadingRelatorios = false

                setTimeout(() => {
                    classeContext.relatorios.map((relatorio, index) => {
                        classeContext.calcularPositionTextoGradient('gradient-id-' + index, 'gradient-text-id-' + index, relatorio.PercentualAprovado)
                    })
                         classeContext.scrollToElementById('listaPacientes');
                 }, 1000)
                console.log(this.relatorios,'relatorios')
            }, error(error) {
                classeContext.isLoadingRelatorios = false
                classeContext.toastr.error('Ocorreu um erro ao mostrar os relatórios dos biomarcadores')
            }
        })
    }

    calcularPositionTextoGradient(gradientId: string, gradientTextId: string, porcentagem: number) {
        const gradientElement = getComputedStyle(document.getElementById(gradientId))
        const gradientElementWidth = Number(gradientElement.getPropertyValue('width').split('px')[0])

        const gradientTextElement = document.getElementById(gradientTextId)
        const gradientTextPosition = (gradientElementWidth * (porcentagem * 0.01))
        gradientTextElement.style.left = `${gradientTextPosition}px`
    }

    scrollToElementById(id: string) {
     
        const element = document.getElementById(id)
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    
      }

      ngOnDestroy() {
        this.relatorios = []
        this.cupons = []
        this.listaAvaliadores = []
      }
}
